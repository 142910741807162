var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "boxed-layout", staticStyle: { "max-width": "unset" } },
    [
      _c("h2", { attrs: { id: "page-heading", "data-cy": "AppUserHeading" } }, [
        _c("span", { attrs: { id: "app-user-heading" } }, [
          _vm._v("App Users"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _c("router-link", {
              attrs: { to: { name: "AppUserCreate" }, custom: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ navigate }) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary create-entity create-app-user",
                          attrs: {
                            id: "create-entity",
                            "data-cy": "entityCreateButton",
                          },
                          on: { click: navigate },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "plus" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v(" Neuen User anlegen ")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.isFetching && _vm.appUsers && _vm.appUsers.length === 0
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _c("span", [_vm._v("No appUsers found")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.appUsers && _vm.appUsers.length > 0
        ? _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "appUsers" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.appUsers, function (appUser) {
                    return _c(
                      "tr",
                      { key: appUser.id, attrs: { "data-cy": "entityTable" } },
                      [
                        _c("td", [_vm._v(_vm._s(appUser.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(appUser.street))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(appUser.zip))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(appUser.city))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(appUser.email) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatGermanDateTime")(
                                appUser.createdDate
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _c(
                            "div",
                            { staticClass: "btn-group" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.removeEntity",
                                      modifiers: { removeEntity: true },
                                    },
                                  ],
                                  staticClass: "btn btn-sm",
                                  attrs: {
                                    "data-cy": "entityDeleteButton",
                                    variant: "danger",
                                    disabled: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prepareRemove(appUser)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "trash" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", { ref: "removeEntity", attrs: { id: "removeEntity" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _c(
            "span",
            {
              attrs: {
                id: "cdpRisikoApp.appUser.delete.question",
                "data-cy": "appUserDeleteDialogHeading",
              },
            },
            [_vm._v("Confirm delete operation")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", { attrs: { id: "gen-delete-appUser-heading" } }, [
            _vm._v("Are you sure you want to delete this App User?"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [_vm._v("\n        Abbrechen\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                id: "gen-confirm-delete-appUser",
                "data-cy": "entityConfirmDeleteButton",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.removeAppUser()
                },
              },
            },
            [_vm._v("\n        Löschen\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.appUsers && _vm.appUsers.length > 0,
              expression: "appUsers && appUsers.length > 0",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  change: _vm.loadPage(_vm.page),
                  "per-page": _vm.itemsPerPage,
                  "total-rows": _vm.totalItems,
                  size: "md",
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("PLZ")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Ort")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Straße")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }, [_vm._v("Login")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Erstellungsdatum")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "row" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }