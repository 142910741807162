
import { Component, Vue, Inject } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { IAppUserDTO } from '@/shared/model/app-user.dto';

import AlertService from '@/shared/alert/alert.service';
import CustomAppUserService from './custom-app-user.service';

@Component({
  mixins: [Vue2Filters.mixin],
})
export default class AppUser extends Vue {
  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = false;
  public totalItems = 0;
  public appUsers: IAppUserDTO[] = [];
  public isFetching = false;
  @Inject('customAppUserService') private customAppUserService: () => CustomAppUserService;
  @Inject('alertService') private alertService: () => AlertService;
  private removeId: number = null;

  public async mounted(): Promise<void> {
    await this.retrieveAllAppUsers();
  }

  public async retrieveAllAppUsers(): Promise<void> {
    this.isFetching = true;
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort(),
    };
    try {
      const res = await this.customAppUserService().retrieveDTO(paginationQuery);

      this.appUsers = res.data;
      this.totalItems = Number(res.headers['x-total-count']);
      this.queryCount = this.totalItems;
      this.isFetching = false;
    } catch (err) {
      this.isFetching = false;
      this.alertService().showHttpError(this, err.response);
    }
  }

  public prepareRemove(instance: IAppUserDTO): void {
    this.removeId = instance.id;
    if (this.$refs.removeEntity as any) {
      (this.$refs.removeEntity as any).show();
    }
  }

  public async removeAppUser(): Promise<void> {
    try {
      await this.customAppUserService().deleteDTO(this.removeId);

      this.alertService().showInfo(this, 'Der Benutzer wurde erfolgreich gelöscht.');
      this.removeId = null;
      await this.retrieveAllAppUsers();
      this.closeDialog();
    } catch (error) {
      this.alertService().showHttpError(this, error.response);
    }
  }

  public sort(): Array<any> {
    const result = [`${this.propOrder},${this.reverse ? 'desc' : 'asc'}`];
    if (this.propOrder !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  public async transition(): Promise<void> {
    await this.retrieveAllAppUsers();
  }

  public closeDialog(): void {
    (this.$refs.removeEntity as any).hide();
  }
}
